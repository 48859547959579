<template>
  <div>
    <Titlebar>
      <h5 slot="title" class="title is-5 is-marginless">Ikoner</h5>
    </Titlebar>

    <div v-if="icons" class="icons-grid">
      <span
        v-for="(icon, i) in icons"
        :key="i"
        :class="icon"
        @click="copy(icon, $event)">
      </span>
    </div>

    <textarea />
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      icons: null,
      selectedIcon: null
    }
  },

  mounted () {
    this.getStyle()
  },

  methods: {
    getStyle () {
      axios.get('/icons.css').then(({ data }) => {
        const icons = data.match(/(([^.]+?)(?=:before )|'\\e(\d*|\w*)')/g)
        this.icons = icons
      })
    },

    copy (icon, event) {
      const textarea = this.$el.querySelector('textarea')
      textarea.textContent = icon

      const range = document.createRange()
      range.selectNode(textarea)

      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
      textarea.select()

      if (typeof window.orientation !== 'undefined') {
        document.activeElement.blur()
      }

      document.execCommand('copy')

      event.target.style.background = '#EB914E'
      event.target.style.color = '#fff'
      setTimeout(() => {
        event.target.style.background = 'transparent'
        event.target.style.color = 'currentColor'
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
textarea {
  opacity: 0;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.icons-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 1.5rem;

  span {
    width: 4rem;
    height: 4rem;
    font-size: 1.5rem;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    transition: all 0.15s ease-in-out;

    &:hover {
      cursor: pointer;
      background: #eaeaea;
    }
  }
}
</style>
